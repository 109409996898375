<template>
	<div ref="formContainer">
		<AddHeaderTitle :Header="Header" />
		<b-col cols="11">
			<AppHeaderInfoAdminList :infos="infos" />
		</b-col>
		<b-col cols="11">
			<b-card>
				<!-- Media -->
				<b-media class>
					<b-row>
						<b-col md="12" offset-md="5" v-if="adminRole == 1">
							<div align-h="center" class="mb-12">
								<b-avatar :src="photo" size="6rem"></b-avatar>
								<br />
								<br />
								<div id="app">
									<input type="file" @change="onFileInput($event)" />
								</div>
								<label> (png/jpeg)</label>
								<br />
							</div>
						</b-col>
					</b-row>
				</b-media>
				<ValidationObserver ref="observer">
					<b-form v-if="webinarData">
						<b-row>
							<b-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<label>Titre <span style="color:red">*</span></label>
									<b-form-textarea
										class="mb-2"
										v-model="webinarData.title"
										placeholder="Titre"
									></b-form-textarea>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
								<br />
								<b-row>
									<b-col v-if="adminRole == 1">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<label
												>Tarif (en euro) <span style="color:red">*</span></label
											>
											<b-form-input
												type="number"
												v-model="webinarData.price"
												placeholder="15"
											></b-form-input>
											<small class="text-danger">{{ errors[0] }}</small>
										</ValidationProvider>
									</b-col>
									<b-col v-if="adminRole == 1">
										<b-form-group>
											<label>Enregistrement du webinar</label>
											<b-form-checkbox
												class="primary mt-1"
												name="check-button"
												v-model="webinarData.recording"
												switch
											>
												<span class="switch-icon-left">
													<feather-icon icon="CheckIcon" />
												</span>
												<span class="switch-icon-right">
													<feather-icon icon="XIcon" />
												</span>
											</b-form-checkbox>
										</b-form-group>
									</b-col>
								</b-row>
							</b-col>

							<b-col v-if="adminRole == 1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<label
										>Date du Webinar <span style="color:red">*</span></label
									>
									<b-form-datepicker
										id="example-datepicker"
										v-model="dayStart"
										class="mb-2"
									></b-form-datepicker>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-row align-v="center">
										<b-col>
											<label
												>Heure de début <span style="color:red">*</span></label
											>
											<b-form-input
												class="mb-2"
												v-model="timeStart"
												type="time"
											></b-form-input>
											<small class="text-danger">{{ errors[0] }}</small>
										</b-col>
									</b-row>
									<!-- <b-time v-model="timeStart"  locale="fr"></b-time> -->
								</ValidationProvider>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-row align-v="center">
										<b-col>
											<label
												>Durée du webinar (en minute)
												<span style="color:red">*</span></label
											>
											<b-form-input
												v-model="webinarData.duration"
												placeholder="15"
											></b-form-input>
											<small class="text-danger">{{ errors[0] }}</small>
										</b-col>
									</b-row>
								</ValidationProvider>
							</b-col>
						</b-row>

						<br />

						<hr />
						<br />

						<b-row>
							<b-col v-if="adminRole == 1">
								<label>Type de webinar</label>
								<b-form-select
									v-model="webinarData.type"
									:options="typeWebinar"
								></b-form-select>
							</b-col>

							<b-col v-if="adminRole == 1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<label
										>Controle de présence (en minute)
										<span style="color:red">*</span></label
									>
									<b-form-input
										v-model="webinarData.controle_frequency"
										placeholder="15"
									></b-form-input>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-col>
						</b-row>
						<br />
						<b-row>
							<b-col v-if="adminRole == 1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<label>Point CEU <span style="color:red">*</span></label>
									<b-form-input
										v-model="webinarData.point_ceu"
										placeholder="10"
									></b-form-input>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-col>
							<b-col v-if="adminRole == 1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<label>Point CFC <span style="color:red">*</span></label>
									<b-form-input
										type="number"
										v-model="webinarData.point_cfc"
										placeholder="10"
									></b-form-input>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-col>

							<b-col v-if="adminRole == 1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<label
										>Min. Score (en %) <span style="color:red">*</span></label
									>
									<b-form-input
										v-model="webinarData.minimal_certificat_score"
										placeholder="10"
										step="0.01"
									>
									</b-form-input>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-col>
						</b-row>
						<br />
						<b-row v-if="adminRole == 1">
							<b-col>
								<label>Le Survey</label>
								<b-form-select
									v-model="currentSurvey"
									:options="listSurvey"
									value-field="text"
								></b-form-select>
							</b-col>
						</b-row>
						<br />
						<b-row>
							<b-col>
								<label>Pré-Quizz</label>
								<b-form-select
									v-model="currentSurveyPreQuiz"
									:options="listQuiz"
									value-field="text"
								></b-form-select>
							</b-col>
							<b-col>
								<label>Post-Quizz</label>
								<b-form-select
									v-model="currentSurveyPostQuiz"
									:options="listQuiz"
									value-field="text"
								></b-form-select>
							</b-col>
						</b-row>
						<br />
						<hr />
						<br />
						<b-row>
							<b-col>
								<label>Objectifs pédagogiques</label>
								<b-form-textarea
									v-model="webinarData.learning_objective"
									placeholder="Objectif"
								></b-form-textarea>
							</b-col>
							<b-col>
								<label>Information générale</label>
								<b-form-textarea
									v-model="webinarData.learning_content"
									placeholder="Learning content"
								>
								</b-form-textarea>
							</b-col>
						</b-row>
						<br />
						<b-row>
							<b-col>
								<label>Prérequis</label>
								<b-form-textarea
									v-model="webinarData.prerequisites"
									placeholder="Prérequis"
								></b-form-textarea>
							</b-col>
							<b-col>
								<label>Contenu & format</label>
								<b-form-textarea
									v-model="webinarData.content"
									placeholder="Content"
								></b-form-textarea>
							</b-col>
						</b-row>
						<br />
						<hr />
						<br />
						<b-row>
							<b-col>
								<div id="app">
									<label>Fichiers joints (pdf)</label>
									<br />
									<input
										type="file"
										@change="onFileAttachment($event)"
										multiple
									/>
								</div>
							</b-col>
							<b-col>
								<div id="app">
									<label>Pdf de présentation</label>
									<br />
									<b-img :src="imgBBB" fluid width="100" alt="no img"></b-img>-
									<input type="file" @change="onFileBoard($event)" />
								</div>
							</b-col>
						</b-row>

						<br />
						<b-row>
							<b-col>
								<div>
									<b-table
										class="mr-2 col-12"
										size="sm"
										striped
										hover
										:items="webinarData.webinar_docs"
										:fields="fields"
									>
										<template #cell(action)="list" cols="6">
											<b-button
												variant="success"
												size="sm"
												@click="openPresentationFile(list.item.path)"
												>Voir
											</b-button>
											<b-button
												variant="danger"
												size="sm"
												@click="deleteDoc(list.item)"
												>Supprimer
											</b-button>
										</template>
									</b-table>
								</div>
							</b-col>
							<b-col>
								<div>
									<b-table
										class="mr-2 col-12"
										size="sm"
										striped
										hover
										:items="[{ id: webinarData.doc_presentation_id }]"
										:fields="fieldsMedia"
									>
										<template #cell(action)="list" cols="2">
											<b-button
												variant="success"
												class="float-right"
												size="sm"
												@click="openPresentationFile(list.item.id)"
												>Voir
											</b-button>
										</template>
									</b-table>
								</div>
							</b-col>
						</b-row>

						<br />
						<hr />
						<br />
						<div v-if="adminRole == 1">
							<label>Ajouter des Speakers</label>
							<b-form-select
								v-model="selected"
								:options="listSpeaker"
								class="mb-1"
							></b-form-select>
							<div class="text-right">
								<b-button
									variant="primary"
									size="sm"
									@click="addSpeaker(selected)"
									>Ajouter</b-button
								>
							</div>
							<br />

							<div>
								<b-table
									class="mr-2"
									size="sm"
									striped
									hover
									:items="webinarData.speakers"
									:fields="fields"
								>
									<template #cell(action)="list" cols="2">
										<b-button
											variant="danger"
											class="float-right"
											size="sm"
											@click="deleteSpeaker(list.item)"
											>Supprimer
										</b-button>
									</template>
								</b-table>
							</div>
						</div>
					</b-form>
					<br />
					<hr />
					<br />
				</ValidationObserver>

				<br />
				<!-- Action Buttons -->
				<div class="text-center">
					<b-button variant="success" @click="update()"
						>Enregistrer les modifications</b-button
					>
				</div>
			</b-card>
		</b-col>
	</div>
</template>
<script>
import {
	BButton,
	BFormInput,
	BForm,
	BCard,
	BRow,
	BCol,
	BFormSelect,
	BTable,
	BFormTextarea,
	BFormDatepicker,
	//BTime,
	BMedia,
	BAvatar,
	BImg,
	BFormCheckbox,
	BFormGroup,
} from "bootstrap-vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import Ripple from "vue-ripple-directive";
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import moment from "moment";
import { Admin } from "@/mixins/admin.js";
import { url } from "@/services/axios";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import {
	zonedTimeToUtc,
	utcToZonedTime,
	format,
	formatInTimeZone,
} from "date-fns-tz";
Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

extend("required", { message: "Ce champ ne peut pas être vide" });
extend("numeric", { message: "Ce champ doit être un nombre" });

//import  { admin } from '@/app/views/admin/admin.js'

export default {
	name: "WebinarDetail",
	directives: {
		Ripple,
	},
	components: {
		AppHeaderInfoAdminList,
		AddHeaderTitle,
		BButton,
		BRow,
		BCol,
		BFormInput,
		BForm,
		BCard,
		BFormSelect,
		BTable,
		BFormTextarea,
		BFormDatepicker,
		//BTime,
		ValidationProvider,
		ValidationObserver,
		BMedia,
		BAvatar,
		BImg,
		BFormCheckbox,
		BFormGroup,
	},
	mixins: [Admin],
	data() {
		return {
			fields: [
				{
					key: "name",
					label: "Nom",
					sortable: true,
				},
				{
					key: "Action",
					label: "Action",
					sortable: false,
					thClass: "text-right",
					tdClass: "text-right",
				},
			],
			fieldsMedia: [
				{
					key: "name",
					label: "Nom",
					sortable: true,
				},
				{
					key: "Action",
					label: "Action",
					sortable: false,
					thClass: "text-right",
					tdClass: "text-right",
				},
			],
			adminRole: this.$store.state.user.user.role,
			items: [{ speakers: "Dickerson" }, { speakers: "Damn" }],
			Header: {
				config: "Admin",
				listLink: "/admin/webinar/list",
				list: "Webinar-liste",
				detailLink: "",
				detail: "Webinar-détail",
			},
			infos: {
				title: "Modification des informations d'un webinar",
				content: "Visualisation des détails d'un webinar et mise à jour.",
			},
			webinarId: this.$route.params.id,

			webinarData: {},
			listSpeaker: [],
			listSurvey: [
				{
					text: "",
					value: "",
				},
			],
			listQuiz: [
				{
					text: "",
					value: "",
				},
			],
			currentSurvey: "",
			currentSurveyPreQuiz: "",
			currentSurveyPostQuiz: "",
			selected: "",
			timeStart: "",
			dayStart: "",
			options: [
				"Apple",
				"Orange",
				"Banana",
				"Lime",
				"Peach",
				"Chocolate",
				"Strawberry",
			],
			value: [],
			currentImage: undefined,
			boardImage: undefined,
			attachment: undefined,
			photo: "",
			imgBBB: "",
			typeWebinar: [
				{
					text: "",
					value: "",
				},
				{
					text: "General",
					value: "GENERAL",
				},
				{
					text: "Ethic",
					value: "ETHIC",
				},
				{
					text: "Supervisor",
					value: "SUPERVISOR",
				},
			],
		};
	},
	async mounted() {
		await this.loadWebinar();
		await this.getListSpeaker();
		await this.getListSurveys();
		await this.getListQuiz();
	},
	computed: {
		availableOptions() {
			return this.options.filter((opt) => this.value.indexOf(opt) === -1);
		},
	},
	methods: {
		async onFileInput(event) {
			this.currentImage = event;
			const data = URL.createObjectURL(event.target.files[0]);
			this.photo = data;
		},

		async onFileBoard(event) {
			this.boardImage = event;
			const data = URL.createObjectURL(event.target.files[0]);
			this.imgBBB = data;
		},

		onFileAttachment(event) {
			this.attachment = event;
		},

		openPresentationFile(id) {
			window.open(url + "media/" + id);
		},

		async loadWebinar() {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.getWebinar(this.webinarId);
				if (response.status == 1) {
					this.webinarData = response.datas;

					const date = new Date(this.webinarData.start_date);

					this.webinarData.doc_presentation = this.webinarData.webinar_docs.filter(doc => doc.path == this.webinarData.doc_presentation_id)

					this.webinarData.webinar_docs = this.webinarData.webinar_docs.filter(doc => doc.type !== 2)

					// const timeZone = "Etc/GMT";
					// const zonedDate = utcToZonedTime(date, timeZone);
					// const patternTime = "HH:mm";
					// this.timeStart = format(zonedDate, patternTime, {
					// 	timeZone: "Etc/GMT",
					// });

					this.timeStart = `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`

					console.log("🚀 ~ loadWebinar ~ this.timeStart:", this.timeStart)
					const patternDay = "yyyy-MM-dd";
					this.dayStart = format(date, patternDay, {
						timeZone: "Etc/GMT",
					});

					this.photo = url + "media/" + this.webinarData.media_id;
					this.imgBBB = url + "media/" + this.webinarData.doc_presentation_id;

					loader.hide();
				} else {
					this.$sweetError("AF-52");
					this.$sweetNotif("Modification réussie");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("AF-52");
			}
		},

		async getListQuiz() {
			const response = await this.getAllQuiz();
			let listQuiz = response.datas;

			let findPreQuiz = listQuiz.filter(
				(quiz) => quiz.id == this.webinarData.pre_quizz
			);
			if (findPreQuiz.length) {
				this.currentSurveyPreQuiz = findPreQuiz[0].name;
			}

			let findPostQuiz = listQuiz.filter(
				(quiz) => quiz.id == this.webinarData.post_quizz
			);

			if (findPostQuiz.length) {
				this.currentSurveyPostQuiz = findPostQuiz[0].name;
			}

			for (let i = 0; i < listQuiz.length; i++) {
				let add = {
					text: listQuiz[i].name,
					value: listQuiz[i],
				};
				this.listQuiz.push(add);
			}
			return this.listQuiz;
		},

		async getListSurveys() {
			const response = await this.getAllSurveys();
			let listSurvey = response.datas;

			let findSurvey = listSurvey.filter(
				(survey) => survey.id == this.webinarData.survey_id
			);

			if (findSurvey.length) {
				this.currentSurvey = findSurvey[0].name;
			}

			for (let i = 0; i < listSurvey.length; i++) {
				let add = {
					text: listSurvey[i].name,
					value: listSurvey[i],
				};
				this.listSurvey.push(add);
			}
			return this.listSurvey;
		},

		async getListSpeaker() {
			const response = await this.getAllSpeaker();
			let listSpeaker = response.datas;

			for (let i = 0; i < listSpeaker.length; i++) {
				let add = {
					text: listSpeaker[i].firstname + " " + listSpeaker[i].lastname,
					value: listSpeaker[i],
				};
				this.listSpeaker.push(add);
			}
			return this.listSpeaker;
		},

		addSpeaker(option) {
			if (option) {
				const index = this.webinarData.speakers.findIndex(
					(speaker) => speaker.id === option.id
				);
				if (!~index) {
					this.webinarData.speakers.push({
						name: option.firstname + " " + option.lastname,
						id: option.id,
					}); //delete the post
				}
			}
		},

		deleteSpeaker(selected) {
			const index = this.webinarData.speakers.findIndex(
				(speaker) => speaker.id === selected.id
			);
			if (index > -1) {
				this.webinarData.speakers.splice(index, 1); //delete the post
			}
			
		},

		async update() {
			const isValid = await this.$refs.observer.validate();
			if (isValid) {
				let loader = this.$loading.show({
					container: this.$refs.formContainer,
					canCancel: true,
				});
				try {
					let findSurvey = this.listSurvey.filter(
						(survey) => survey.text == this.currentSurvey
					);
					if (findSurvey.length) {
						this.webinarData.survey_id = findSurvey[0].value.id;
					}

					let findPreQuiz = this.listQuiz.filter(
						(quiz) => quiz.text == this.currentSurveyPreQuiz
					);
					if (findPreQuiz.length) {
						this.webinarData.pre_quizz = findPreQuiz[0].value.id;
					}

					let findPostQuiz = this.listQuiz.filter(
						(quiz) => quiz.text == this.currentSurveyPostQuiz
					);
					if (findPostQuiz.length) {
						this.webinarData.post_quizz = findPostQuiz[0].value.id;
					}

					let ids = this.webinarData.speakers.map((speaker) => speaker.id);
					this.webinarData.speakers = ids;

					this.webinarData.start_date = this.dayStart + " " + this.timeStart;

					if (this.boardImage) {
						await this.uploadIMG(
							this.boardImage,
							this.webinarData.id,
							"WEBINAR_DOC_INTRO"
						);
					}
					if (this.attachment) {
						await this.uploadMultiFile(
							this.attachment,
							this.webinarData.id,
							"WEBINAR_DOCS"
						);
					}

					const response = await this.updateWebinar(this.webinarData);

					if (response.status == 1) {
						if (this.currentImage) {
							await this.uploadIMG(
								this.currentImage,
								this.webinarData.id,
								"WEBINAR_MEDIA"
							);
						}
						this.$sweetNotif("Modification réussie");
						this.$router.push({ path: `/admin/webinar/list` });
					} else {
						this.$sweetError("AF-51");
					}
					loader.hide();
				} catch {
					loader.hide();
					this.$sweetError("AF-51");
				}
			}
		},

		getDate(date) {
			moment.locale("fr");
			return moment(date)
				.startOf("day")
				.fromNow();
		},
		getDateCreation(date) {
			moment.locale("fr");
			return moment(date).format("LL");
		},
		getAvatar(firstname) {
			const init = firstname.substr(0, 2);
			return init;
		},

		async deleteDoc(item) {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.deleteWebinarDoc(item.id);
				if (response.status === 1) {
					this.$sweetNotif("Document supprimé");
					const index = this.webinarData.webinar_docs.findIndex(
						(doc) => doc.id === item.id
					);
					if (~index) {
						this.webinarData.webinar_docs.splice(index, 1); //delete the post
					}
					loader.hide();
				} else {
					this.$sweetError("DELETE-DOCS");
					loader.hide();
				}
			} catch {
				loader.hide();
				this.$sweetError("DELETE-DOCS-1");
			}
		},
	},
};
</script>
